
















import { Component } from 'vue-property-decorator';
import { BaseView } from '@shared/views/BaseView';
import { Company, CompanySearchRequest } from '@shared/models';
import CompanyItem from './CompanyItem.vue';

@Component({
	components: {
		CompanyItem,
	},
})
export default class PopularCompanies extends BaseView {

	public list: Company[] = [];

	public async mounted() {
		this.loading = true;
		try {
			const result = await this.$api.companyService.search(new CompanySearchRequest({ isPopular: true, pageSize: 12 }));
			this.list = result.results;
		} catch (err) {
			this.errorHandler.handle(err, 'An error occurred while loading popular companies');
		} finally {
			this.loading = false;
		}
	}

}

