
















import { Component } from 'vue-property-decorator';
import { BaseView } from '@shared/views/BaseView';
import { Deck, DeckSearchRequest } from '@shared/models';
import DeckItem from './DeckItem.vue';

@Component({
	components: {
		DeckItem,
	},
})
export default class RecentChanges extends BaseView {


	public list: Deck[] = [];

	public async mounted() {
		this.loading = true;
		try {
			const result = await this.$api.deckService.search(new DeckSearchRequest({ pageSize: 24, sortBy: 'updateddate', sortDirection: 'desc' }));
			this.list = result.results;
		} catch (err) {
			this.errorHandler.handle(err, 'An error occurred while loading recent deck changes');
		} finally {
			this.loading = false;
		}
	}
	

}

