








































import { Component } from 'vue-property-decorator';
import { BaseView } from '@shared/views/BaseView';
import PopularCompanies from '@client/views/industry/companies/PopularCompanies.vue';
import RecentChanges from '@client/views/products/decks/RecentChanges.vue';
import RecentAdditions from '@client/views/products/decks/RecentAdditions.vue';
import RecentEntries from '@client/views/blog/RecentEntries.vue';
import ContactWidget from '@client/views/common/ContactWidget.vue';

@Component({
	components: {
		PopularCompanies,
		RecentAdditions,
		RecentChanges,
		RecentEntries,
		ContactWidget,
	},
})
export default class Home extends BaseView {


}

